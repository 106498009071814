import request from "@/utils/request";

export function detailAbsentSubject(data) {
  return request({
    method: "post",
    url: "lesson_schedule/lesson_absence_detail",
    data: data
  });
}

export function absenceSubject(data) {
  return request({
    method: "post",
    url: "awor/get_absences",
    data: data
  });
}

export function refreshAbsent(data) {
  return request({
    method: "post",
    url: "cron/generate_awor_absen",
    data: data
  });
}

export function getTeachersByClass(data) {
  return request({
    method: "post",
    url: "employee/list_teacher_by_class",
    data: data
  });
}

export function nameByNIS(data) {
  return request({
    method: "post",
    url: "student/name_by_nis",
    data: data
  });
}

export function saveNIS(data) {
  return request({
    method: "post",
    url: "lesson_absence/edit_nis",
    data: data
  });
}

export function deleteMultipleAbsent(data) {
  return request({
    method: "post",
    url: "lesson_absence/delete_active_participants",
    data: data
  });
}

export function getJoiningTime(data) {
  return request({
    method: "post",
    url: "lesson_absence/get_joining_time",
    data: data
  });
}
