<template>
  <div>
    <div v-if="$vuetify.breakpoint.lgAndUp">
      <div class="d-flex">
        <v-select
          v-model="modelIdSchoolYear"
          :items="listSchoolYear"
          :label="$t('master_data.student.school_year')"
          :item-text="
            item =>
              `${item.start_year} / ${item.end_year}, semester ${item.semester}`
          "
          :loading="loadingSchoolYear"
          disabled
          item-value="id"
          outlined
          dense
          class="mr-2 select-250 d-inline-block"
        >
          <template #item="{ item }">
            <span class="caption">
              {{ item.start_year }} / {{ item.end_year }}, semester
              {{ item.semester }}
            </span>
            <v-tooltip right>
              <template #activator="{ on }">
                <v-icon
                  v-on="on"
                  :color="schoolYearInfo(item.status).color"
                  small
                  class="ml-1"
                >
                  {{ schoolYearInfo(item.status).name }}
                </v-icon>
              </template>
              <span>{{ schoolYearInfo(item.status).info }}</span>
            </v-tooltip>
          </template>
        </v-select>
        <v-select
          v-model="modelGrade"
          :items="listGrade"
          :label="$t('schedule.choose_grade')"
          :loading="loadingGrade"
          item-value="id"
          item-text="grade"
          outlined
          dense
          class="mr-2 select-150 d-inline-block"
          @change="changeGrade"
        />
        <v-select
          v-model="modelClass"
          :items="listClass"
          :label="$t('academic.schedule_data.type_of_class')"
          :loading="loadingClass"
          item-value="id"
          item-text="name"
          outlined
          dense
          class="select-200 d-inline-block"
        />
      </div>
    </div>

    <div v-else class="d-flex align-center mb-3">
      <v-btn color="primary" @click="sheet = !sheet" depressed>
        <v-icon>mdi-filter-outline</v-icon>
      </v-btn>
    </div>

    <v-bottom-sheet v-model="sheet">
      <v-sheet class="text-center pa-6">
        <v-row>
          <v-select
            v-model="modelIdSchoolYear"
            :items="listSchoolYear"
            :label="$t('master_data.student.school_year')"
            :item-text="
              item =>
                `${item.start_year} / ${item.end_year}, semester ${item.semester}`
            "
            :loading="loadingSchoolYear"
            disabled
            hide-details
            item-value="id"
            outlined
            dense
            class="mr-2 mt-3"
          >
            <template #item="{ item }">
              <span class="caption">
                {{ item.start_year }} / {{ item.end_year }}, semester
                {{ item.semester }}
              </span>
              <v-tooltip right>
                <template #activator="{ on }">
                  <v-icon
                    v-on="on"
                    :color="schoolYearInfo(item.status).color"
                    small
                    class="ml-1"
                  >
                    {{ schoolYearInfo(item.status).name }}
                  </v-icon>
                </template>
                <span>{{ schoolYearInfo(item.status).info }}</span>
              </v-tooltip>
            </template>
          </v-select>
          <v-select
            v-model="modelGrade"
            :items="listGrade"
            :label="$t('schedule.choose_grade')"
            :loading="loadingGrade"
            item-value="id"
            item-text="grade"
            hide-details
            outlined
            dense
            class="mr-2 mt-3"
            @change="changeGrade"
          />
          <v-select
            v-model="modelClass"
            :items="listClass"
            :label="$t('academic.schedule_data.type_of_class')"
            :loading="loadingClass"
            item-value="id"
            item-text="name"
            hide-details
            outlined
            dense
            class="mr-2 mt-3"
          />
        </v-row>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<script>
import {
  get_school_year_list,
  get_class_list,
  get_grade_list
} from "@/api/admin/schoolClass";
import { getTeachersByClass } from "@/api/admin/absent/recapAbsentSubject";
import moment from "moment/moment";

export default {
  props: {
    idClass: Number,
    idSchoolYear: Number,
    itemTeacher: Array,
    idGrade: Number
  },
  created() {
    moment.locale(this.$store.getters.g_language);
    this.getSchoolYear();
    this.getGrade();
  },
  watch: {
    idSchoolYear() {
      this.changeGrade(this.modelGrade);
    }
  },
  computed: {
    modelTeacher: {
      set(newValue) {
        this.setTeacher(newValue);
      },
      get() {
        return this.itemTeacher;
      }
    },
    modelClass: {
      set(newValue) {
        this.setClass(newValue);
      },
      get() {
        return this.idClass;
      }
    },
    modelGrade: {
      set(newValue) {
        this.setGrade(newValue);
      },
      get() {
        return this.idGrade;
      }
    },
    modelIdSchoolYear: {
      set(newValue) {
        this.setIdSchoolYear(newValue);
      },
      get() {
        return this.idSchoolYear;
      }
    }
  },
  data() {
    return {
      idMonth: null,
      loadingReset: false,
      modalMaster: false,
      modalEvent: false,
      menuSubject: false,
      monthList: [],
      listSchoolYear: [],
      idSchoolYearActive: 0,
      listGrade: [],
      listClass: [],
      loadingSchoolYear: false,
      loadingGrade: false,
      loadingClass: false,
      sheet: false,
      selectAllTeacher: false,
      fields: {
        Class: "kelas",
        "NIP Homeroom Teacher": "no_pendaftaran",
        "Name Homeroom Teacher": "wali",
        "NIP Conselor": "no_kartu",
        "Conselor Name": "nama",
        "Total Students": "id"
      },
      isAdmin: this.$store.getters.g_role_type === "ADMIN",
      typeList:
        this.$store.getters.g_role_type === "TEACHER" ? "homeroom_teacher" : "",
      loadingTeacher: false,
      listTeachers: []
    };
  },
  methods: {
    changeTeacher() {
      this.selectAllTeacher = false;
      if (this.listTeachers.length === this.modelTeacher.length) {
        this.selectAllTeacher = true;
      }
    },
    handleClickAll() {
      this.selectAllTeacher = !this.selectAllTeacher;
      this.modelTeacher = [];
      if (this.selectAllTeacher)
        this.modelTeacher = this.listTeachers.map(r => r.teacher_id);
    },
    getTeachers(idClass) {
      if (idClass) {
        this.loadingTeacher = true;
        getTeachersByClass({ classroom: idClass })
          .then(res => {
            if (res.data.code) {
              this.listTeachers = res.data.data;
            }
            this.loadingTeacher = false;
          })
          .catch(err => {
            this.loadingTeacher = false;
            console.error("getTeachers()\n", err);
          });
      }
    },
    getMonthName(id) {
      if (id) {
        return this.$i18n.t("absent.month").find(item => item.id === id).value;
      }
    },
    getRangeMonth(start, end) {
      const format = "YYYY-MM-DD";
      const startDate = moment(start, format),
        endDate = moment(end, format);
      const result = [];
      while (startDate.isBefore(endDate)) {
        result.push({
          year: startDate.startOf("month").format("YYYY"),
          month: startDate.startOf("month").format("M")
        });
        startDate.add(1, "month");
      }
      return result;
    },
    setIdSchoolYear(value) {
      const itemSchoolYear = this.listSchoolYear.find(
        item => item.id === value
      );
      this.monthList = this.getRangeMonth(
        itemSchoolYear.start_date,
        itemSchoolYear.end_date
      );
      const currMonth = moment().format("M");
      const currYear = moment().format("YYYY");
      this.idMonth = this.monthList.find(
        item => item.year === currYear && item.month === currMonth
      );
      this.changeMonth(this.idMonth);
      this.$emit("setIdSchoolYear", value);
    },
    schoolYearInfo(status) {
      let icon = { name: "", color: "", info: "" };
      const active = {
        name: "mdi-check-circle-outline",
        color: "success",
        info: this.$i18n.t("school_year.current_school_year")
      };
      const willCome = {
        name: "mdi-timer-sand",
        color: "grey",
        info: this.$i18n.t("school_year.upcoming_school_year")
      };
      switch (status) {
        case 1:
          icon = active;
          break;
        case 2:
          icon = willCome;
          break;
      }
      return icon;
    },
    changeMonth(value) {
      this.$emit("setIdMonth", value);
    },
    setClass(value) {
      this.getTeachers(value);
      this.$emit("setIdClass", value);
    },
    setGrade(value) {
      this.$emit("setIdGrade", value);
    },
    setTeacher(value) {
      this.$emit("setTeacher", value);
    },
    openModalMaster() {
      this.modalMaster = !this.modalMaster;
    },
    openModalEvent() {
      this.modalEvent = !this.modalEvent;
    },
    changeGrade(grade) {
      if (grade) {
        this.loadingClass = true;
        get_class_list({
          grade: [grade],
          school_year: [this.modelIdSchoolYear],
          type: this.typeList.toUpperCase()
        })
          .then(res => {
            if (res.data.code == 1) {
              let r = res.data.data;
              this.listClass = r;
              this.modelClass = parseInt(this.$route.query.class || 0);
            } else {
              this.snackBar(true, res.data.message);
            }
            this.loadingClass = false;
          })
          .catch(err => {
            console.error("changeGrade(grade)\n", err);
            this.loadingClass = false;
            this.snackBar(true, err);
          });
      }
    },
    snackBar(isError, msg) {
      this.$store.commit("CALL_SNACKBAR", {
        msg: msg,
        color: isError ? "error" : "success"
      });
      this.loadingSave = false;
    },
    getClass() {
      this.loadingGrade = true;
      get_class_list()
        .then(res => {
          if (res.data.code == 1) {
            let r = res.data.data;
            this.listGrade = r;
          } else {
            this.snackBar(true, res.data.message);
          }
          this.loadingGrade = false;
        })
        .catch(err => {
          this.loadingGrade = false;
          this.snackBar(true, err);
        });
    },
    getGrade() {
      this.loadingGrade = true;
      get_grade_list()
        .then(res => {
          if (res.data.code == 1) {
            let r = res.data.data;
            this.listGrade = r;
          } else {
            this.snackBar(true, res.data.message);
          }
          this.loadingGrade = false;
        })
        .catch(err => {
          this.loadingGrade = false;
          this.snackBar(true, err);
        });
    },
    getSchoolYear() {
      this.loadingSchoolYear = true;
      get_school_year_list(false)
        .then(res => {
          if (res.data.code == 1) {
            let r = res.data.data;
            this.listSchoolYear = r;
            let schoolYearActive = r.find(d => d.status === 1)?.id;
            this.modelGrade = parseInt(this.$route.query.grade || 0);
            if (schoolYearActive) {
              this.modelIdSchoolYear = schoolYearActive;
              this.idSchoolYearActive = schoolYearActive;
            }
          } else {
            this.snackBar(true, res.data.message);
          }
          this.loadingSchoolYear = false;
        })
        .catch(err => {
          console.error("getSchoolYear()\n", err);
          this.loadingSchoolYear = false;
          this.snackBar(true, err);
        });
    }
  }
};
</script>
